import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import isSandboxEnvironment from "../utils/is-sandbox-environment";
import { useAuth0 } from "@auth0/auth0-react";

function Sidebar({
  data,
  setDashboardLinkData,
  setDashboardViewStatus,
  link,
  isNavbarOpen,
  isEmptyWorkbooks,
  isVIP,
}) {
  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const { isAuthenticated, logout } = useAuth0();
  const username = sessionStorage.getItem("Username");
  const email = username;
  const emailDomain = email.split("@").pop();


  useEffect(() => {
  }, [data]);
  return (
    <div
      className={`${
        isNavbarOpen ? "min-w-[450px]" : "w-0"
      } transition-all duration-200 ease-in-out pb-4 h-[auto] overflow-auto  bg-gradient-to-b from-[#001A33] to-[#00404C] via-[#00468A] animate-gradient-y flex flex-col justify-between items-start gap-[5vh]`}
    >
      <div
        className={`${
          isSandboxEnvironment() && isNavbarOpen ? "block" : "hidden"
        } z-10 absolute top-0 left-0 w-[450px] bg-[#ff0000] text-[white] text-center h-[27px]`}
      >
        🏗️ This is a sandboxed instance
      </div>
      <div
        className={`flex flex-col justify-center items-center gap-4 text-[#D9E6F2] w-[100%] rounded-[15px] px-4 mt-${
          isSandboxEnvironment() ? "8" : "0"
        }`}
      >
        <div className="h-[72px] mt-6 ">
          <img src="logo-63px.png" alt="" className="h-[63px]" />
        </div>
        <div className="flex flex-col w-full rounded-[3px] overflow-hidden">
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className={`bg-[#203660] ${
                  index !== 0 && "border-t-[1px] border-[#ffffff0d]"
                }`}
              >
                <Accordion className={`w-[100%] no-border bg-[#203660]`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="w-[100%] navigation"
                  >
                    <div
                      className={`flex flex-row justify-start items-center gap-8 cursor-pointer bg-[#203660] w-[100%] z-[10]`}
                    >
                      <p className={`text-[18px] text-white`}>{item.project}</p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    className="bg-[#001A4D]"
                    style={{ padding: 10 }}
                  >
                    <div className="bg-[#001A4D]">
                      <div className="text-[16px] ml-[0%] text-white">
                        <ul className="flex flex-col list-none">
                          {item.workbooks.map((workbook, index) => {
                            return (
                              <li
                                key={index}
                                className={`antialiased font-medium cursor-pointer brightness-100 hover:brightness-150 py-2 px-4 bg-[#001A4D] ${
                                  link === workbook?.views[0]?.contentUrl &&
                                  "text-[#4785f8]"
                                }`}
                                onClick={() => {
                                  if (link !== workbook?.views[0]?.contentUrl) {
                                    setDashboardViewStatus(true);
                                    setDashboardLinkData(
                                      workbook.views[0].contentUrl
                                    );
                                  } else {
                                    setDashboardViewStatus(false);
                                    setDashboardLinkData("");
                                  }
                                }}
                              >
                                {workbook.name.split("(")[0]}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
          {isEmptyWorkbooks && (
    <div className="w-full h-[400px] flex flex-row justify-center items-center">
      <p>
        No workbooks or projects to display.<br /> To request support or access, please{" "}
        <a href="https://www.theqadimgroup.com/r/C8m" className="text-blue-500 underline">
        contact us
        </a>.
    </p>
    </div>
          )}
        </div>
        <div className="flex flex-row w-[100%] gap-4 justify-between items-center">
          <a
            href="https://www.theqadimgroup.com/r/E8I"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-[#091F49] px-3 py-2 rounded-[30px] text-[#4D8AFF] text-center text-xs">
              Support
            </button>
          </a>
          <div className="flex flex-row justify-center items-center gap-4">
            <a
              href="https://prod-ca-a.online.tableau.com/#/site/qadencebytqg/explore"
              target="_blank"
              rel="noreferrer"
            >
              <p className="text-[#4D8AFF] font-[400] text-xs">Tableau Cloud</p>
            </a>
            <a
              href="http://theqadimgroup.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <p className="text-[#4D8AFF] font-[400] text-xs">
                Privacy Policy
              </p>
            </a>
            <a
              href="http://theqadimgroup.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <p className="text-[#4D8AFF] font-[400] text-xs">Terms of Use</p>
            </a>
          </div>
        </div>
      </div>
      <div className="w-[100%] flex flex-row justify-center items-center ">
        <div className="flex flex-row justify-start items-center w-[90%]">
          <div
            className={`flex w-[100%] px-4 ${
              !isVIP
                ? "flex-col justify-start items-start py-2  bg-[#203660]  shadow-md rounded-md"
                : "flex-row justify-between items-center py-4 bg-[#f0f5ff]  shadow-md rounded-md"
            }`}
          >
            {!isVIP ? (
              <p className="text-[#D9E6F2] text-md font-[500]">{username}</p>
            ) : (
              <div className="flex gap-2 items-center">
                <div>
                  <img src={`client-logos/${emailDomain}(small).png`} />
                </div>
                <div className="font-[500] text-[#203660]">{username}</div>
              </div>
            )}
            <button
              className={`font-[500] ${
                !isVIP ? "text-[#8CB3D9]" : "text-[#203660]"
              }`}
              onClick={() => {
                if (!isAuthenticated && isUserLoggedIn) {
                  document.cookie =
                  "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                  sessionStorage.removeItem("isLogged");
                  sessionStorage.removeItem("Username");
                  navigate("/login");
                  window.location.reload();
                } else if (isAuthenticated) {
                  sessionStorage.removeItem("isLogged");
                  sessionStorage.removeItem("Username");
                  logout({ logoutParams: { returnTo: window.location.origin, federated: true}});
                }              
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
